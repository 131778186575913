.ekexopz_static-pages {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.p5c3jcz_static-pages {
  margin-top: 84px;
  @media (max-width: 767px) {
    margin-top: 0;
  }
}

.e1t81b4d_static-pages {
  margin-top: var(--space-400);
}

.esz6mnu_static-pages {
  margin-top: var(--space-200);
  line-height: 22px !important;
  text-align: center;
}

.r1jik0gc_static-pages {
  margin-top: var(--space-400);
}

.g71sto9_static-pages {
  margin-top: var(--space-300);
  margin-bottom: 114px;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9kYXRhL2plbmtpbnMtcm9vdC93b3Jrc3BhY2UvcnUtLXRyaWZmbGUtLXN0YXRpYy1wYWdlc19tYXN0ZXIvc3JjL3BhZ2VzLzUwMC9zdHlsZXMudHMiXSwibmFtZXMiOlsiLmVrZXhvcHpfc3RhdGljLXBhZ2VzIiwiLnA1YzNqY3pfc3RhdGljLXBhZ2VzIiwiLmUxdDgxYjRkX3N0YXRpYy1wYWdlcyIsIi5lc3o2bW51X3N0YXRpYy1wYWdlcyIsIi5yMWppazBnY19zdGF0aWMtcGFnZXMiLCIuZzcxc3RvOV9zdGF0aWMtcGFnZXMiXSwibWFwcGluZ3MiOiJBQUlnQ0E7QUFTUkM7QUFPSUM7QUFHSUM7QUFLSEM7QUFHREMiLCJmaWxlIjoiL2RhdGEvamVua2lucy1yb290L3dvcmtzcGFjZS9ydS0tdHJpZmZsZS0tc3RhdGljLXBhZ2VzX21hc3Rlci9zcmMvcGFnZXMvNTAwL3N0eWxlcy50cyIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCB7IHN0eWxlZCB9IGZyb20gJ2xpbmFyaWEvcmVhY3QnO1xuaW1wb3J0IHsgY3NzIH0gZnJvbSAnbGluYXJpYSc7XG5pbXBvcnQgeyBicmVha3BvaW50cyB9IGZyb20gJy4uLy4uL2NvbW1vbi9zdHlsZXMvYnJlYWtwb2ludHMnO1xuXG5leHBvcnQgY29uc3QgRXJyb3JQYWdlV3JhcHBlciA9IHN0eWxlZC5kaXZgXG4gIGRpc3BsYXk6IGZsZXg7XG4gIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuICB3aWR0aDogMTAwJTtcbiAgaGVpZ2h0OiAxMDB2aDtcbmA7XG5cbmV4cG9ydCBjb25zdCBwYWludEltZyA9IGNzc2BcbiAgbWFyZ2luLXRvcDogODRweDtcbiAgJHticmVha3BvaW50cy5vbmx5Lm1vYmlsZX0ge1xuICAgIG1hcmdpbi10b3A6IDA7XG4gIH1cbmA7XG5cbmV4cG9ydCBjb25zdCBlcnJvckhlYWRpbmcgPSBjc3NgXG4gIG1hcmdpbi10b3A6IHZhcigtLXNwYWNlLTQwMCk7XG5gO1xuZXhwb3J0IGNvbnN0IGVycm9yRGVzY3JpcHRpb24gPSBjc3NgXG4gIG1hcmdpbi10b3A6IHZhcigtLXNwYWNlLTIwMCk7XG4gIGxpbmUtaGVpZ2h0OiAyMnB4ICFpbXBvcnRhbnQ7XG4gIHRleHQtYWxpZ246IGNlbnRlcjtcbmA7XG5leHBvcnQgY29uc3QgcmVmcmVzaEJ1dHRvbiA9IGNzc2BcbiAgbWFyZ2luLXRvcDogdmFyKC0tc3BhY2UtNDAwKTtcbmA7XG5leHBvcnQgY29uc3QgZ29Ib21lQnV0dG9uID0gY3NzYFxuICBtYXJnaW4tdG9wOiB2YXIoLS1zcGFjZS0zMDApO1xuICBtYXJnaW4tYm90dG9tOiAxMTRweDtcbmA7XG4iXX0=*/