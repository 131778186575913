#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

*,
:after,
:before {
  box-sizing: border-box;
}

html {
  font-size: 14px;
}

@media (min-width: 420px) and (max-width: 767px) {
  html {
    font-size: 3.33333vw;
  }
}

body {
  min-height: 100vh;
  margin: 0;
  overflow-x: hidden;
  font-family:
    Roboto,
    apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Oxygen,
    Ubuntu,
    Cantarell,
    Open Sans,
    Helvetica Neue,
    sans-serif;
  background-color: #fff;
  touch-action: manipulation;
  -webkit-font-smoothing: subpixel-antialiased;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

:not(:defined) {
  visibility: hidden;
}

[hidden-before-defined]:not(:defined) {
  position: absolute !important;
}

:root {
  --layout-gap: 16px;
  --layout-inner-gap: 12px;
  --layout-columns-count: 24;
  --layout-min-width: 320px;
  --layout-width: 100%;
  --layout-max-width: 1180px;
}
@media (min-width: 768px) {
  :root {
    --layout-gap: 54px;
    --layout-width: 660px;
  }
}
@media (min-width: 1024px) {
  :root {
    --layout-gap: 42px;
    --layout-inner-gap: 20px;
    --layout-width: 940px;
  }
}

@media (min-width: 1200px) {
  :root {
    --layout-gap: 10px;
    --layout-width: var(--layout-max-width);
  }
}

.visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden;
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px);
}
