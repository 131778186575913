.cbdiwx0_static-pages {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: var(--space-350);

  @media (min-width: 768px) {
    margin-top: var(--space-800);
  }
}

.d1mk2czq_static-pages {
  display: flex;
  flex-direction: column;
  width: 574px;
}

.b2yx2lf_static-pages {
  margin-top: var(--space-400);

  @media (max-width: 767px) {
    width: 100%;
  }
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9kYXRhL2plbmtpbnMtcm9vdC93b3Jrc3BhY2UvcnUtLXRyaWZmbGUtLXN0YXRpYy1wYWdlc19tYXN0ZXIvc3JjL3BhZ2VzL3JlZ2lvbi1zdHViLXBhZ2UvY29tcG9uZW50cy9tYWluLWluZm8vc3R5bGVzLnRzIl0sIm5hbWVzIjpbIi5jYmRpd3gwX3N0YXRpYy1wYWdlcyIsIi5kMW1rMmN6cV9zdGF0aWMtcGFnZXMiLCIuYjJ5eDJsZl9zdGF0aWMtcGFnZXMiXSwibWFwcGluZ3MiOiJBQUd5QkE7QUFXRUM7QUFNSUMiLCJmaWxlIjoiL2RhdGEvamVua2lucy1yb290L3dvcmtzcGFjZS9ydS0tdHJpZmZsZS0tc3RhdGljLXBhZ2VzX21hc3Rlci9zcmMvcGFnZXMvcmVnaW9uLXN0dWItcGFnZS9jb21wb25lbnRzL21haW4taW5mby9zdHlsZXMudHMiLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgeyBjc3MgfSBmcm9tICdsaW5hcmlhJztcbmltcG9ydCB7IGJyZWFrcG9pbnRzIH0gZnJvbSAnY29tbW9uL3N0eWxlcy9icmVha3BvaW50cyc7XG5cbmV4cG9ydCBjb25zdCBjb250YWluZXIgPSBjc3NgXG4gIGRpc3BsYXk6IGZsZXg7XG4gIGp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjtcbiAgd2lkdGg6IDEwMCU7XG4gIG1hcmdpbi10b3A6IHZhcigtLXNwYWNlLTM1MCk7XG5cbiAgJHticmVha3BvaW50cy5mcm9tLnRhYmxldH0ge1xuICAgIG1hcmdpbi10b3A6IHZhcigtLXNwYWNlLTgwMCk7XG4gIH1cbmA7XG5cbmV4cG9ydCBjb25zdCBkZXNjcmlwdGlvbiA9IGNzc2BcbiAgZGlzcGxheTogZmxleDtcbiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgd2lkdGg6IDU3NHB4O1xuYDtcblxuZXhwb3J0IGNvbnN0IGJ1dHRvbkNvbnRhaW5lciA9IGNzc2BcbiAgbWFyZ2luLXRvcDogdmFyKC0tc3BhY2UtNDAwKTtcblxuICAke2JyZWFrcG9pbnRzLnRvLnRhYmxldH0ge1xuICAgIHdpZHRoOiAxMDAlO1xuICB9XG5gO1xuIl19*/