import { css } from 'linaria';

export const container = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: var(--space-250) 0;
`;

export const textRegion = css`
  flex: 1 1 auto;
  margin-top: var(--space-150);
  margin-left: var(--space-300);
`;
