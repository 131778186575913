import { breakpoints } from 'common/styles/breakpoints';
import { styled } from 'linaria/react';

export const Cards = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: var(--space-200);
  width: 100vw;
  padding: var(--space-800) var(--layout-gap);
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  -webkit-scroll-snap-type: none;

  &::-webkit-scrollbar {
    display: none;
  }

  ${breakpoints.from.tablet} {
    flex-direction: row;
    gap: var(--space-200);
    padding: var(--space-600) var(--space-675);
    margin: var(--space-400) 0;
  }

  ${breakpoints.from.desktop} {
    justify-content: center;
    flex-wrap: nowrap;
    padding-top: var(--space-600);
    padding-bottom: var(--space-600);
  }
`;
