.spvccej_static-pages {
  padding: var(--space-75) 0;
  background-image: url('./square.svg');
  background-repeat: repeat-x;
  @media (max-width: 767px) {
    width: 1730px;
    overflow: hidden;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 2184px;
    overflow: hidden;
  }
  @media (min-width: 1024px) {
    width: 5750px;
    overflow: hidden;
  }
  @media (min-width: 2064px) {
    width: 6050px;
  }
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9kYXRhL2plbmtpbnMtcm9vdC93b3Jrc3BhY2UvcnUtLXRyaWZmbGUtLXN0YXRpYy1wYWdlc19tYXN0ZXIvc3JjL2NvbW1vbi9pY29ucy9zcXVhcmVkLWJvcmRlci50cyJdLCJuYW1lcyI6WyIuc3B2Y2Nlal9zdGF0aWMtcGFnZXMiXSwibWFwcGluZ3MiOiJBQUdvQ0EiLCJmaWxlIjoiL2RhdGEvamVua2lucy1yb290L3dvcmtzcGFjZS9ydS0tdHJpZmZsZS0tc3RhdGljLXBhZ2VzX21hc3Rlci9zcmMvY29tbW9uL2ljb25zL3NxdWFyZWQtYm9yZGVyLnRzIiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IHsgc3R5bGVkIH0gZnJvbSAnbGluYXJpYS9yZWFjdCc7XG5pbXBvcnQgeyBicmVha3BvaW50cyB9IGZyb20gJ2NvbW1vbi9zdHlsZXMvYnJlYWtwb2ludHMnO1xuXG5leHBvcnQgY29uc3QgU3F1YXJlZEJvcmRlcldyYXBwZXIgPSBzdHlsZWQuZGl2YFxuICBwYWRkaW5nOiB2YXIoLS1zcGFjZS03NSkgMDtcbiAgYmFja2dyb3VuZC1pbWFnZTogdXJsKCcuL3NxdWFyZS5zdmcnKTtcbiAgYmFja2dyb3VuZC1yZXBlYXQ6IHJlcGVhdC14O1xuICAke2JyZWFrcG9pbnRzLm9ubHkubW9iaWxlfSB7XG4gICAgd2lkdGg6IDE3MzBweDtcbiAgICBvdmVyZmxvdzogaGlkZGVuO1xuICB9XG4gICR7YnJlYWtwb2ludHMub25seS50YWJsZXR9IHtcbiAgICB3aWR0aDogMjE4NHB4O1xuICAgIG92ZXJmbG93OiBoaWRkZW47XG4gIH1cbiAgJHticmVha3BvaW50cy5mcm9tLmRlc2t0b3B9IHtcbiAgICB3aWR0aDogNTc1MHB4O1xuICAgIG92ZXJmbG93OiBoaWRkZW47XG4gIH1cbiAgQG1lZGlhIChtaW4td2lkdGg6IDIwNjRweCkge1xuICAgIHdpZHRoOiA2MDUwcHg7XG4gIH1cbmA7XG4iXX0=*/