.cdqyp5c_static-pages {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: var(--space-200);
  width: 100vw;
  padding: var(--space-800) var(--layout-gap);
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  -webkit-scroll-snap-type: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    gap: var(--space-200);
    padding: var(--space-600) var(--space-675);
    margin: var(--space-400) 0;
  }

  @media (min-width: 1024px) {
    justify-content: center;
    flex-wrap: nowrap;
    padding-top: var(--space-600);
    padding-bottom: var(--space-600);
  }
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9kYXRhL2plbmtpbnMtcm9vdC93b3Jrc3BhY2UvcnUtLXRyaWZmbGUtLXN0YXRpYy1wYWdlc19tYXN0ZXIvc3JjL2NvbW1vbi9jb21wb25lbnRzL2NhcmRzL2NhcmRzLnRzeCJdLCJuYW1lcyI6WyIuY2RxeXA1Y19zdGF0aWMtcGFnZXMiXSwibWFwcGluZ3MiOiJBQUdxQkEiLCJmaWxlIjoiL2RhdGEvamVua2lucy1yb290L3dvcmtzcGFjZS9ydS0tdHJpZmZsZS0tc3RhdGljLXBhZ2VzX21hc3Rlci9zcmMvY29tbW9uL2NvbXBvbmVudHMvY2FyZHMvY2FyZHMudHN4Iiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IHsgYnJlYWtwb2ludHMgfSBmcm9tICdjb21tb24vc3R5bGVzL2JyZWFrcG9pbnRzJztcbmltcG9ydCB7IHN0eWxlZCB9IGZyb20gJ2xpbmFyaWEvcmVhY3QnO1xuXG5leHBvcnQgY29uc3QgQ2FyZHMgPSBzdHlsZWQuZGl2YFxuICBkaXNwbGF5OiBmbGV4O1xuICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICBmbGV4LXdyYXA6IHdyYXA7XG4gIGdhcDogdmFyKC0tc3BhY2UtMjAwKTtcbiAgd2lkdGg6IDEwMHZ3O1xuICBwYWRkaW5nOiB2YXIoLS1zcGFjZS04MDApIHZhcigtLWxheW91dC1nYXApO1xuICAtbXMtb3ZlcmZsb3ctc3R5bGU6IG5vbmU7XG4gIHNjcm9sbGJhci13aWR0aDogbm9uZTtcbiAgLXdlYmtpdC1vdmVyZmxvdy1zY3JvbGxpbmc6IHRvdWNoO1xuICAtd2Via2l0LXNjcm9sbC1zbmFwLXR5cGU6IG5vbmU7XG5cbiAgJjo6LXdlYmtpdC1zY3JvbGxiYXIge1xuICAgIGRpc3BsYXk6IG5vbmU7XG4gIH1cblxuICAke2JyZWFrcG9pbnRzLmZyb20udGFibGV0fSB7XG4gICAgZmxleC1kaXJlY3Rpb246IHJvdztcbiAgICBnYXA6IHZhcigtLXNwYWNlLTIwMCk7XG4gICAgcGFkZGluZzogdmFyKC0tc3BhY2UtNjAwKSB2YXIoLS1zcGFjZS02NzUpO1xuICAgIG1hcmdpbjogdmFyKC0tc3BhY2UtNDAwKSAwO1xuICB9XG5cbiAgJHticmVha3BvaW50cy5mcm9tLmRlc2t0b3B9IHtcbiAgICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgICBmbGV4LXdyYXA6IG5vd3JhcDtcbiAgICBwYWRkaW5nLXRvcDogdmFyKC0tc3BhY2UtNjAwKTtcbiAgICBwYWRkaW5nLWJvdHRvbTogdmFyKC0tc3BhY2UtNjAwKTtcbiAgfVxuYDtcbiJdfQ==*/