.h1qlh24z_static-pages {
  margin-bottom: var(--space-200);
  color: var(--text-primary);
}

.htap4qz_static-pages {
  margin: var(--space-300) 0 var(--space-200);
  color: var(--text-primary);
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9kYXRhL2plbmtpbnMtcm9vdC93b3Jrc3BhY2UvcnUtLXRyaWZmbGUtLXN0YXRpYy1wYWdlc19tYXN0ZXIvc3JjL3BhZ2VzL3Nob2NrLWNlbmEvc3R5bGVzLnRzIl0sIm5hbWVzIjpbIi5oMXFsaDI0el9zdGF0aWMtcGFnZXMiLCIuaHRhcDRxel9zdGF0aWMtcGFnZXMiXSwibWFwcGluZ3MiOiJBQUV1QkE7QUFLVUMiLCJmaWxlIjoiL2RhdGEvamVua2lucy1yb290L3dvcmtzcGFjZS9ydS0tdHJpZmZsZS0tc3RhdGljLXBhZ2VzX21hc3Rlci9zcmMvcGFnZXMvc2hvY2stY2VuYS9zdHlsZXMudHMiLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgeyBjc3MgfSBmcm9tICdsaW5hcmlhJztcblxuZXhwb3J0IGNvbnN0IGhlYWRpbmcgPSBjc3NgXG4gIG1hcmdpbi1ib3R0b206IHZhcigtLXNwYWNlLTIwMCk7XG4gIGNvbG9yOiB2YXIoLS10ZXh0LXByaW1hcnkpO1xuYDtcblxuZXhwb3J0IGNvbnN0IGhlYWRpbmdNb2JpbGVWaWV3ID0gY3NzYFxuICBtYXJnaW46IHZhcigtLXNwYWNlLTMwMCkgMCB2YXIoLS1zcGFjZS0yMDApO1xuICBjb2xvcjogdmFyKC0tdGV4dC1wcmltYXJ5KTtcbmA7XG4iXX0=*/