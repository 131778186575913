.r1pjeuho_static-pages {
  width: 100%;
  margin-top: var(--space-450);
  columns: 4;
  column-gap: var(--space-800);

  @media (min-width: 1024px) and (max-width: 1199px) {
    columns: 3;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    columns: 2;
  }
  @media (max-width: 767px) {
    columns: 1;
  }
}

.riw7x4b_static-pages {
  display: block;
  width: 240px;
  padding: var(--space-150) var(--space-200) var(--space-150) 0;
  text-decoration: none;
  color: var(--text-primary);
  &:hover {
    color: var(--brand-dark);
  }
}

.r137xbjf_static-pages {
  height: 48px;
}

.c14vc63z_static-pages {
  @media (min-width: 768px) {
    padding: var(--space-500) 0;
    margin-bottom: var(--space-1000);
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    padding-top: var(--space-300);
  }
  @media (max-width: 767px) {
    padding-top: var(--space-100);
  }
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9kYXRhL2plbmtpbnMtcm9vdC93b3Jrc3BhY2UvcnUtLXRyaWZmbGUtLXN0YXRpYy1wYWdlc19tYXN0ZXIvc3JjL3BhZ2VzL3JlZ2lvbi1zdHViLXBhZ2UvY29tcG9uZW50cy9yZWdpb24tbGlzdC9zdHlsZXMudHMiXSwibmFtZXMiOlsiLnIxcGpldWhvX3N0YXRpYy1wYWdlcyIsIi5yaXc3eDRiX3N0YXRpYy1wYWdlcyIsIi5yMTM3eGJqZl9zdGF0aWMtcGFnZXMiLCIuYzE0dmM2M3pfc3RhdGljLXBhZ2VzIl0sIm1hcHBpbmdzIjoiQUFHMEJBO0FBaUJBQztBQVdLQztBQUlOQyIsImZpbGUiOiIvZGF0YS9qZW5raW5zLXJvb3Qvd29ya3NwYWNlL3J1LS10cmlmZmxlLS1zdGF0aWMtcGFnZXNfbWFzdGVyL3NyYy9wYWdlcy9yZWdpb24tc3R1Yi1wYWdlL2NvbXBvbmVudHMvcmVnaW9uLWxpc3Qvc3R5bGVzLnRzIiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IHsgY3NzIH0gZnJvbSAnbGluYXJpYSc7XG5pbXBvcnQgeyBicmVha3BvaW50cyB9IGZyb20gJ2NvbW1vbi9zdHlsZXMvYnJlYWtwb2ludHMnO1xuXG5leHBvcnQgY29uc3QgcmVnaW9uTGlzdCA9IGNzc2BcbiAgd2lkdGg6IDEwMCU7XG4gIG1hcmdpbi10b3A6IHZhcigtLXNwYWNlLTQ1MCk7XG4gIGNvbHVtbnM6IDQ7XG4gIGNvbHVtbi1nYXA6IHZhcigtLXNwYWNlLTgwMCk7XG5cbiAgJHticmVha3BvaW50cy5vbmx5LmRlc2t0b3B9IHtcbiAgICBjb2x1bW5zOiAzO1xuICB9XG4gICR7YnJlYWtwb2ludHMub25seS50YWJsZXR9IHtcbiAgICBjb2x1bW5zOiAyO1xuICB9XG4gICR7YnJlYWtwb2ludHMudG8udGFibGV0fSB7XG4gICAgY29sdW1uczogMTtcbiAgfVxuYDtcblxuZXhwb3J0IGNvbnN0IHJlZ2lvbkl0ZW0gPSBjc3NgXG4gIGRpc3BsYXk6IGJsb2NrO1xuICB3aWR0aDogMjQwcHg7XG4gIHBhZGRpbmc6IHZhcigtLXNwYWNlLTE1MCkgdmFyKC0tc3BhY2UtMjAwKSB2YXIoLS1zcGFjZS0xNTApIDA7XG4gIHRleHQtZGVjb3JhdGlvbjogbm9uZTtcbiAgY29sb3I6IHZhcigtLXRleHQtcHJpbWFyeSk7XG4gICY6aG92ZXIge1xuICAgIGNvbG9yOiB2YXIoLS1icmFuZC1kYXJrKTtcbiAgfVxuYDtcblxuZXhwb3J0IGNvbnN0IHJlZ2lvbkV4dHJhSXRlbSA9IGNzc2BcbiAgaGVpZ2h0OiA0OHB4O1xuYDtcblxuZXhwb3J0IGNvbnN0IGNvbnRhaW5lciA9IGNzc2BcbiAgJHticmVha3BvaW50cy5mcm9tLnRhYmxldH0ge1xuICAgIHBhZGRpbmc6IHZhcigtLXNwYWNlLTUwMCkgMDtcbiAgICBtYXJnaW4tYm90dG9tOiB2YXIoLS1zcGFjZS0xMDAwKTtcbiAgfVxuXG4gICR7YnJlYWtwb2ludHMub25seS50YWJsZXR9IHtcbiAgICBwYWRkaW5nLXRvcDogdmFyKC0tc3BhY2UtMzAwKTtcbiAgfVxuICAke2JyZWFrcG9pbnRzLnRvLnRhYmxldH0ge1xuICAgIHBhZGRpbmctdG9wOiB2YXIoLS1zcGFjZS0xMDApO1xuICB9XG5gO1xuIl19*/