.so498uq_static-pages {
  width: 100%;
  margin-bottom: var(--space-250);
  @media (max-width: 767px) {
    a {
      font-size: 12px !important;
      line-height: 16px !important;
    }
  }
  @media (min-width: 1024px) {
    margin: var(--space-300) 0;
  }
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9kYXRhL2plbmtpbnMtcm9vdC93b3Jrc3BhY2UvcnUtLXRyaWZmbGUtLXN0YXRpYy1wYWdlc19tYXN0ZXIvc3JjL2ZlYXR1cmVzL2JyZWFkY3J1bWJzL2NvbXBvbmVudHMvc3R5bGUudHMiXSwibmFtZXMiOlsiLnNvNDk4dXFfc3RhdGljLXBhZ2VzIl0sIm1hcHBpbmdzIjoiQUFHNEJBIiwiZmlsZSI6Ii9kYXRhL2plbmtpbnMtcm9vdC93b3Jrc3BhY2UvcnUtLXRyaWZmbGUtLXN0YXRpYy1wYWdlc19tYXN0ZXIvc3JjL2ZlYXR1cmVzL2JyZWFkY3J1bWJzL2NvbXBvbmVudHMvc3R5bGUudHMiLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgeyBjc3MgfSBmcm9tICdsaW5hcmlhJztcbmltcG9ydCB7IGJyZWFrcG9pbnRzIH0gZnJvbSAnY29tbW9uL3N0eWxlcy9icmVha3BvaW50cyc7XG5cbmV4cG9ydCBjb25zdCBzZWN0aW9uU3R5bGUgPSBjc3NgXG4gIHdpZHRoOiAxMDAlO1xuICBtYXJnaW4tYm90dG9tOiB2YXIoLS1zcGFjZS0yNTApO1xuICAke2JyZWFrcG9pbnRzLm9ubHkubW9iaWxlfSB7XG4gICAgYSB7XG4gICAgICBmb250LXNpemU6IDEycHggIWltcG9ydGFudDtcbiAgICAgIGxpbmUtaGVpZ2h0OiAxNnB4ICFpbXBvcnRhbnQ7XG4gICAgfVxuICB9XG4gICR7YnJlYWtwb2ludHMuZnJvbS5kZXNrdG9wfSB7XG4gICAgbWFyZ2luOiB2YXIoLS1zcGFjZS0zMDApIDA7XG4gIH1cbmA7XG4iXX0=*/