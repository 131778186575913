.skidp8q_static-pages {
  margin: var(--space-200) 0 var(--space-450);
  font-size: 12px;
  line-height: 20px;
}

.c1hkxqad_static-pages {
  padding-top: var(--space-500);
  padding-bottom: var(--space-1000);
  color: var(--text-minor);

  a {
    color: var(--brand-dark);
  }
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9kYXRhL2plbmtpbnMtcm9vdC93b3Jrc3BhY2UvcnUtLXRyaWZmbGUtLXN0YXRpYy1wYWdlc19tYXN0ZXIvc3JjL3BhZ2VzL3JlZ2lvbi1zdHViLXBhZ2UvY29tcG9uZW50cy9zZW8tZGVzY3JpcHRpb24vc3R5bGUudHMiXSwibmFtZXMiOlsiLnNraWRwOHFfc3RhdGljLXBhZ2VzIiwiLmMxaGt4cWFkX3N0YXRpYy1wYWdlcyJdLCJtYXBwaW5ncyI6IkFBRThCQTtBQU1MQyIsImZpbGUiOiIvZGF0YS9qZW5raW5zLXJvb3Qvd29ya3NwYWNlL3J1LS10cmlmZmxlLS1zdGF0aWMtcGFnZXNfbWFzdGVyL3NyYy9wYWdlcy9yZWdpb24tc3R1Yi1wYWdlL2NvbXBvbmVudHMvc2VvLWRlc2NyaXB0aW9uL3N0eWxlLnRzIiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IHsgY3NzIH0gZnJvbSAnbGluYXJpYSc7XG5cbmV4cG9ydCBjb25zdCBzZW9EZXNjcmlwdGlvbiA9IGNzc2BcbiAgbWFyZ2luOiB2YXIoLS1zcGFjZS0yMDApIDAgdmFyKC0tc3BhY2UtNDUwKTtcbiAgZm9udC1zaXplOiAxMnB4O1xuICBsaW5lLWhlaWdodDogMjBweDtcbmA7XG5cbmV4cG9ydCBjb25zdCBjb250YWluZXIgPSBjc3NgXG4gIHBhZGRpbmctdG9wOiB2YXIoLS1zcGFjZS01MDApO1xuICBwYWRkaW5nLWJvdHRvbTogdmFyKC0tc3BhY2UtMTAwMCk7XG4gIGNvbG9yOiB2YXIoLS10ZXh0LW1pbm9yKTtcblxuICBhIHtcbiAgICBjb2xvcjogdmFyKC0tYnJhbmQtZGFyayk7XG4gIH1cbmA7XG4iXX0=*/