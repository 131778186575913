.b1qnmkbx_static-pages {
  width: 100%;
  height: var(--space-500);
  margin-top: var(--space-1000);
  background: linear-gradient(180deg, rgba(70, 76, 92, 0.04) 0%, rgba(255, 255, 255, 0) 100%);

  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: var(--space-800);
  }
  @media (max-width: 767px) {
    margin-top: var(--space-550);
  }
}

.c1gk64em_static-pages {
  position: relative;
}

.p1ppczx2_static-pages {
  align-self: center;
  margin-top: var(--space-350);
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9kYXRhL2plbmtpbnMtcm9vdC93b3Jrc3BhY2UvcnUtLXRyaWZmbGUtLXN0YXRpYy1wYWdlc19tYXN0ZXIvc3JjL3BhZ2VzL3JlZ2lvbi1zdHViLXBhZ2Uvc3R5bGVzLnRzIl0sIm5hbWVzIjpbIi5iMXFubWtieF9zdGF0aWMtcGFnZXMiLCIuYzFnazY0ZW1fc3RhdGljLXBhZ2VzIiwiLnAxcHBjengyX3N0YXRpYy1wYWdlcyJdLCJtYXBwaW5ncyI6IkFBRzhCQTtBQWNMQztBQUlFQyIsImZpbGUiOiIvZGF0YS9qZW5raW5zLXJvb3Qvd29ya3NwYWNlL3J1LS10cmlmZmxlLS1zdGF0aWMtcGFnZXNfbWFzdGVyL3NyYy9wYWdlcy9yZWdpb24tc3R1Yi1wYWdlL3N0eWxlcy50cyIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCB7IGNzcyB9IGZyb20gJ2xpbmFyaWEnO1xuaW1wb3J0IHsgYnJlYWtwb2ludHMgfSBmcm9tICdjb21tb24vc3R5bGVzL2JyZWFrcG9pbnRzJztcblxuZXhwb3J0IGNvbnN0IGJhY2tncm91bmRMaW5lID0gY3NzYFxuICB3aWR0aDogMTAwJTtcbiAgaGVpZ2h0OiB2YXIoLS1zcGFjZS01MDApO1xuICBtYXJnaW4tdG9wOiB2YXIoLS1zcGFjZS0xMDAwKTtcbiAgYmFja2dyb3VuZDogbGluZWFyLWdyYWRpZW50KDE4MGRlZywgcmdiYSg3MCwgNzYsIDkyLCAwLjA0KSAwJSwgcmdiYSgyNTUsIDI1NSwgMjU1LCAwKSAxMDAlKTtcblxuICAke2JyZWFrcG9pbnRzLm9ubHkudGFibGV0fSB7XG4gICAgbWFyZ2luLXRvcDogdmFyKC0tc3BhY2UtODAwKTtcbiAgfVxuICAke2JyZWFrcG9pbnRzLnRvLnRhYmxldH0ge1xuICAgIG1hcmdpbi10b3A6IHZhcigtLXNwYWNlLTU1MCk7XG4gIH1cbmA7XG5cbmV4cG9ydCBjb25zdCBjb250YWluZXIgPSBjc3NgXG4gIHBvc2l0aW9uOiByZWxhdGl2ZTtcbmA7XG5cbmV4cG9ydCBjb25zdCBwaG9uZU51bWJlciA9IGNzc2BcbiAgYWxpZ24tc2VsZjogY2VudGVyO1xuICBtYXJnaW4tdG9wOiB2YXIoLS1zcGFjZS0zNTApO1xuYDtcbiJdfQ==*/