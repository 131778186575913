.cids9xj_static-pages {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--space-500);
  @media (min-width: 768px) {
    width: 100%;
    margin-bottom: 0;
  }
  @media (min-width: 1200px) {
    min-width: 378px;
    max-width: 271px;
  }
}

.h1vah46f_static-pages {
  height: var(--space-100);
}

.c1uhl27y_static-pages {
  margin-bottom: var(--space-150);
}

.c1i2vrfv_static-pages {
  margin-top: var(--space-150);
  span {
    color: var(--brand-dark) !important;
  }
  &:hover {
    span {
      color: var(--brand-primary) !important;
    }
  }
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9kYXRhL2plbmtpbnMtcm9vdC93b3Jrc3BhY2UvcnUtLXRyaWZmbGUtLXN0YXRpYy1wYWdlc19tYXN0ZXIvc3JjL2NvbW1vbi9jb21wb25lbnRzL2NvbnRhY3QtdW5pdC9zdHlsZXMudHMiXSwibmFtZXMiOlsiLmNpZHM5eGpfc3RhdGljLXBhZ2VzIiwiLmgxdmFoNDZmX3N0YXRpYy1wYWdlcyIsIi5jMXVobDI3eV9zdGF0aWMtcGFnZXMiLCIuYzFpMnZyZnZfc3RhdGljLXBhZ2VzIl0sIm1hcHBpbmdzIjoiQUFJa0NBO0FBY0pDO0FBSUZDO0FBR0RDIiwiZmlsZSI6Ii9kYXRhL2plbmtpbnMtcm9vdC93b3Jrc3BhY2UvcnUtLXRyaWZmbGUtLXN0YXRpYy1wYWdlc19tYXN0ZXIvc3JjL2NvbW1vbi9jb21wb25lbnRzL2NvbnRhY3QtdW5pdC9zdHlsZXMudHMiLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgeyBzdHlsZWQgfSBmcm9tICdsaW5hcmlhL3JlYWN0JztcbmltcG9ydCB7IGNzcyB9IGZyb20gJ2xpbmFyaWEnO1xuaW1wb3J0IHsgYnJlYWtwb2ludHMgfSBmcm9tICdjb21tb24vc3R5bGVzL2JyZWFrcG9pbnRzJztcblxuZXhwb3J0IGNvbnN0IENvbnRhY3RVbml0V3JhcHBlciA9IHN0eWxlZC5kaXZgXG4gIGRpc3BsYXk6IGZsZXg7XG4gIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gIG1hcmdpbi1ib3R0b206IHZhcigtLXNwYWNlLTUwMCk7XG4gICR7YnJlYWtwb2ludHMuZnJvbS50YWJsZXR9IHtcbiAgICB3aWR0aDogMTAwJTtcbiAgICBtYXJnaW4tYm90dG9tOiAwO1xuICB9XG4gICR7YnJlYWtwb2ludHMub25seS5sYXJnZURlc2t0b3B9IHtcbiAgICBtaW4td2lkdGg6IDM3OHB4O1xuICAgIG1heC13aWR0aDogMjcxcHg7XG4gIH1cbmA7XG5cbmV4cG9ydCBjb25zdCBIYXJkY29kZU1hcmdpbiA9IHN0eWxlZC5kaXZgXG4gIGhlaWdodDogdmFyKC0tc3BhY2UtMTAwKTtcbmA7XG5cbmV4cG9ydCBjb25zdCBjb250YWN0VGl0bGUgPSBjc3NgXG4gIG1hcmdpbi1ib3R0b206IHZhcigtLXNwYWNlLTE1MCk7XG5gO1xuZXhwb3J0IGNvbnN0IGNvbnRhY3RMaW5rID0gY3NzYFxuICBtYXJnaW4tdG9wOiB2YXIoLS1zcGFjZS0xNTApO1xuICBzcGFuIHtcbiAgICBjb2xvcjogdmFyKC0tYnJhbmQtZGFyaykgIWltcG9ydGFudDtcbiAgfVxuICAmOmhvdmVyIHtcbiAgICBzcGFuIHtcbiAgICAgIGNvbG9yOiB2YXIoLS1icmFuZC1wcmltYXJ5KSAhaW1wb3J0YW50O1xuICAgIH1cbiAgfVxuYDtcbiJdfQ==*/