.s5vhg19_static-pages {
  display: flex;
  flex-direction: column;
  grid-row-gap: var(--space-200);
  height: auto;
  margin-bottom: var(--space-800);
  @media (min-width: 1024px) {
    display: grid;
    grid-column-gap: var(--space-300);
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: var(--space-1000);
  }
}

.cczz81e_static-pages {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--space-250);
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    gap: var(--space-800);
    margin-bottom: 90px;
  }
  @media (min-width: 1200px) {
    gap: var(--space-300);
  }
  @media (min-width: 1024px) and (max-width: 1199px) {
    justify-content: flex-start;
  }
}

.mfmufve_static-pages {
  margin-bottom: var(--space-400);

  @media (max-width: 1023px) {
    margin-bottom: var(--space-300);
  }
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9kYXRhL2plbmtpbnMtcm9vdC93b3Jrc3BhY2UvcnUtLXRyaWZmbGUtLXN0YXRpYy1wYWdlc19tYXN0ZXIvc3JjL3BhZ2VzL2NvbnRhY3RzL3N0eWxlcy50cyJdLCJuYW1lcyI6WyIuczV2aGcxOV9zdGF0aWMtcGFnZXMiLCIuY2N6ejgxZV9zdGF0aWMtcGFnZXMiLCIubWZtdWZ2ZV9zdGF0aWMtcGFnZXMiXSwibWFwcGluZ3MiOiJBQUk0QkE7QUFjQ0M7QUFrQkhDIiwiZmlsZSI6Ii9kYXRhL2plbmtpbnMtcm9vdC93b3Jrc3BhY2UvcnUtLXRyaWZmbGUtLXN0YXRpYy1wYWdlc19tYXN0ZXIvc3JjL3BhZ2VzL2NvbnRhY3RzL3N0eWxlcy50cyIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCB7IHN0eWxlZCB9IGZyb20gJ2xpbmFyaWEvcmVhY3QnO1xuaW1wb3J0IHsgY3NzIH0gZnJvbSAnbGluYXJpYSc7XG5pbXBvcnQgeyBicmVha3BvaW50cyB9IGZyb20gJ2NvbW1vbi9zdHlsZXMvYnJlYWtwb2ludHMnO1xuXG5leHBvcnQgY29uc3QgU3VwcG9ydEJsb2NrID0gc3R5bGVkLmRpdmBcbiAgZGlzcGxheTogZmxleDtcbiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgZ3JpZC1yb3ctZ2FwOiB2YXIoLS1zcGFjZS0yMDApO1xuICBoZWlnaHQ6IGF1dG87XG4gIG1hcmdpbi1ib3R0b206IHZhcigtLXNwYWNlLTgwMCk7XG4gICR7YnJlYWtwb2ludHMuZnJvbS5kZXNrdG9wfSB7XG4gICAgZGlzcGxheTogZ3JpZDtcbiAgICBncmlkLWNvbHVtbi1nYXA6IHZhcigtLXNwYWNlLTMwMCk7XG4gICAgZ3JpZC10ZW1wbGF0ZS1jb2x1bW5zOiByZXBlYXQoMywgMWZyKTtcbiAgICBtYXJnaW4tYm90dG9tOiB2YXIoLS1zcGFjZS0xMDAwKTtcbiAgfVxuYDtcblxuZXhwb3J0IGNvbnN0IENvbnRhY3RzQmxvY2sgPSBzdHlsZWQuZGl2YFxuICBkaXNwbGF5OiBmbGV4O1xuICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICBtYXJnaW4tYm90dG9tOiB2YXIoLS1zcGFjZS0yNTApO1xuICAke2JyZWFrcG9pbnRzLmZyb20udGFibGV0fSB7XG4gICAgZmxleC1kaXJlY3Rpb246IHJvdztcbiAgICBqdXN0aWZ5LWNvbnRlbnQ6IHNwYWNlLWJldHdlZW47XG4gICAgZ2FwOiB2YXIoLS1zcGFjZS04MDApO1xuICAgIG1hcmdpbi1ib3R0b206IDkwcHg7XG4gIH1cbiAgJHticmVha3BvaW50cy5vbmx5LmxhcmdlRGVza3RvcH0ge1xuICAgIGdhcDogdmFyKC0tc3BhY2UtMzAwKTtcbiAgfVxuICAke2JyZWFrcG9pbnRzLm9ubHkuZGVza3RvcH0ge1xuICAgIGp1c3RpZnktY29udGVudDogZmxleC1zdGFydDtcbiAgfVxuYDtcblxuZXhwb3J0IGNvbnN0IG1haW5IZWFkZXIgPSBjc3NgXG4gIG1hcmdpbi1ib3R0b206IHZhcigtLXNwYWNlLTQwMCk7XG5cbiAgJHticmVha3BvaW50cy50by5kZXNrdG9wfSB7XG4gICAgbWFyZ2luLWJvdHRvbTogdmFyKC0tc3BhY2UtMzAwKTtcbiAgfVxuYDtcbiJdfQ==*/