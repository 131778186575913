.i18xy8ez_static-pages {
  display: flex;
  width: 100%;
  padding: var(--space-150);
  background-color: var(--background-secondary);
  border-radius: var(--rounding-l);
}

.i1nemyjs_static-pages {
  padding: var(--space-50);
}

.cwpx9v_static-pages {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--text-primary);
  a {
    text-decoration: underline;
  }
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9kYXRhL2plbmtpbnMtcm9vdC93b3Jrc3BhY2UvcnUtLXRyaWZmbGUtLXN0YXRpYy1wYWdlc19tYXN0ZXIvc3JjL2NvbW1vbi9jb21wb25lbnRzL2luZm9iYXIvc3R5bGUudHMiXSwibmFtZXMiOlsiLmkxOHh5OGV6X3N0YXRpYy1wYWdlcyIsIi5pMW5lbXlqc19zdGF0aWMtcGFnZXMiLCIuY3dweDl2X3N0YXRpYy1wYWdlcyJdLCJtYXBwaW5ncyI6IkFBRWdDQTtBQVFMQztBQUlKQyIsImZpbGUiOiIvZGF0YS9qZW5raW5zLXJvb3Qvd29ya3NwYWNlL3J1LS10cmlmZmxlLS1zdGF0aWMtcGFnZXNfbWFzdGVyL3NyYy9jb21tb24vY29tcG9uZW50cy9pbmZvYmFyL3N0eWxlLnRzIiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IHsgc3R5bGVkIH0gZnJvbSAnbGluYXJpYS9yZWFjdCc7XG5cbmV4cG9ydCBjb25zdCBJbmZvYmFyQ29udGFpbmVyID0gc3R5bGVkLmRpdmBcbiAgZGlzcGxheTogZmxleDtcbiAgd2lkdGg6IDEwMCU7XG4gIHBhZGRpbmc6IHZhcigtLXNwYWNlLTE1MCk7XG4gIGJhY2tncm91bmQtY29sb3I6IHZhcigtLWJhY2tncm91bmQtc2Vjb25kYXJ5KTtcbiAgYm9yZGVyLXJhZGl1czogdmFyKC0tcm91bmRpbmctbCk7XG5gO1xuXG5leHBvcnQgY29uc3QgSWNvbldyYXBwZXIgPSBzdHlsZWQuZGl2YFxuICBwYWRkaW5nOiB2YXIoLS1zcGFjZS01MCk7XG5gO1xuXG5leHBvcnQgY29uc3QgQ29udGVudCA9IHN0eWxlZC5kaXZgXG4gIGRpc3BsYXk6IGZsZXg7XG4gIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gIHdpZHRoOiAxMDAlO1xuICBjb2xvcjogdmFyKC0tdGV4dC1wcmltYXJ5KTtcbiAgYSB7XG4gICAgdGV4dC1kZWNvcmF0aW9uOiB1bmRlcmxpbmU7XG4gIH1cbmA7XG4iXX0=*/