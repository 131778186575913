import { breakpoints } from 'common/styles/breakpoints';
import { styled } from 'linaria/react';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 271px;
  width: 100%;
  padding: var(--space-175) var(--space-200) var(--space-400);
  background-color: var(--background-secondary);
  border-radius: var(--rounding-l);
  user-select: none;
  ${breakpoints.from.tablet} {
    flex: calc(50% - var(--space-100) - var(--space-675));
    width: 50%;
  }
  ${breakpoints.from.desktop} {
    flex: unset;
    min-width: unset;
    width: 223px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: var(--space-25);
  a {
    align-items: flex-start;
    margin-top: var(--space-300);
    color: var(--brand-dark) !important;
    &:hover {
      color: var(--text-primary) !important;
    }
  }
`;

export const CardImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: var(--space-225);
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 170px;
  height: 170px;
`;
