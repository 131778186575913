.c1q090h9_static-pages {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: var(--space-250) 0;
}

.ttg29ps_static-pages {
  flex: 1 1 auto;
  margin-top: var(--space-150);
  margin-left: var(--space-300);
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9kYXRhL2plbmtpbnMtcm9vdC93b3Jrc3BhY2UvcnUtLXRyaWZmbGUtLXN0YXRpYy1wYWdlc19tYXN0ZXIvc3JjL3BhZ2VzL3JlZ2lvbi1zdHViLXBhZ2UvY29tcG9uZW50cy9oZWFkZXIvc3R5bGVzLnRzIl0sIm5hbWVzIjpbIi5jMXEwOTBoOV9zdGF0aWMtcGFnZXMiLCIudHRnMjlwc19zdGF0aWMtcGFnZXMiXSwibWFwcGluZ3MiOiJBQUV5QkE7QUFRQ0MiLCJmaWxlIjoiL2RhdGEvamVua2lucy1yb290L3dvcmtzcGFjZS9ydS0tdHJpZmZsZS0tc3RhdGljLXBhZ2VzX21hc3Rlci9zcmMvcGFnZXMvcmVnaW9uLXN0dWItcGFnZS9jb21wb25lbnRzL2hlYWRlci9zdHlsZXMudHMiLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgeyBjc3MgfSBmcm9tICdsaW5hcmlhJztcblxuZXhwb3J0IGNvbnN0IGNvbnRhaW5lciA9IGNzc2BcbiAgZGlzcGxheTogZmxleDtcbiAgZmxleC1kaXJlY3Rpb246IHJvdztcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgd2lkdGg6IDEwMCU7XG4gIHBhZGRpbmc6IHZhcigtLXNwYWNlLTI1MCkgMDtcbmA7XG5cbmV4cG9ydCBjb25zdCB0ZXh0UmVnaW9uID0gY3NzYFxuICBmbGV4OiAxIDEgYXV0bztcbiAgbWFyZ2luLXRvcDogdmFyKC0tc3BhY2UtMTUwKTtcbiAgbWFyZ2luLWxlZnQ6IHZhcigtLXNwYWNlLTMwMCk7XG5gO1xuIl19*/